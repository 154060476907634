/**
 * This file is part of Keith-mifsud.me
 *
 * @licence Please view the Licence file supplied with this source code.
 *
 * @author Keith Mifsud <https://www.keith-mifsud.me>
 *
 * @copyright Keith Mifsud 2019 <mifsud.k@gmail.com>
 *
 * @since   2.0
 * @version 2.0 Second Major Release
 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DeckList from './DeckList'
import SwipeList from './SwipeList'

class LatestArticles extends Component {
  render () {
    const {
      listStyle,
      showLimit,
      excludeTitle
    } = this.props

    let listComponent

    if (listStyle === 'deck') {
      listComponent = <DeckList
        showLimit={showLimit}
        excludeTitle={excludeTitle}
      />
    } else if (listStyle === 'swipe') {
      listComponent = <SwipeList
        showLimit={showLimit}
        excludeTitle={excludeTitle}
      />
    }
    return (
      <div className={`latest-article-list`}>
        {listComponent}
      </div>
    )
  }
}

LatestArticles.propTypes = {
  listStyle: PropTypes.oneOf(['deck', 'swipe']).isRequired,
  showLimit: PropTypes.number.isRequired,
  excludeTitle: PropTypes.string.isRequired
}

export default LatestArticles
