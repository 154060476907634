/**
 * This file is part of Keith-mifsud.me
 *
 * @licence Please view the Licence file supplied with this source code.
 *
 * @author Keith Mifsud <https://www.keith-mifsud.me>
 *
 * @copyright Keith Mifsud 2019 <mifsud.k@gmail.com>
 *
 * @since   2.0
 * @version 2.0 Second Major Release
 */

import React, { Component } from 'react'
import rehypeReact from 'rehype-react'
import DefaultLayout from './../layouts/Default'
import SEO from './../components/Seo'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import Cta from './../components/Cta'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import config from './../data/SiteConfig'
import ExternalLink from './../components/ExternalLink'
import LatestArticles from './../components/Articles/Latest'
import MiniSubscriptionForm from './../components/SubscribeForm/Mini'
import SocialShare from './../components/SocialShare'
import Comments from './../components/Comments'

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    'external-link': ExternalLink,
    'subscription-form': MiniSubscriptionForm
  }
}).Compiler

/**
 * Article template.
 */
class PostTemplate extends Component {
  render () {
    const post = this.props.data.postData
    const postMeta = this.props.data.postMetaData

    return (
      <DefaultLayout className={`page-article markdown-content-page`}>
        <SEO
          pageType={'article'}
          pageTitle={post.frontmatter.title}
          fullPageTitle={post.frontmatter.title}
          pageDescription={post.frontmatter.description}
          pageImage={postMeta.frontmatter.static_image}
          pagePath={post.frontmatter.path}
          articlePublishDate={postMeta.frontmatter.date}
          articleTags={post.frontmatter.tags}
          canonicalUrl={postMeta.frontmatter.canonical_url}
        />
        <div className="container">
          <div className="row pt-lg-12 pt-10">
            <article className="col-lg-9 col-12">
              <h1>{post.frontmatter.title}</h1>
              <p className={`tags-container`}>{post.frontmatter.tags.map(tag =>
                <span key={tag} className={`badge badge-secondary mr-2 tag`}>{tag}</span>
              )}</p>
              <div className="row">
                <div className="col-12">
                  <p className={`article-details`}><small>Last updated: {post.frontmatter.date} By: <Link to={ config.defaultAuthor.relative_url } title={ `Written by ${ config.defaultAuthor.name }` } className={`hover-none hover-darken`}>{ config.defaultAuthor.name }</Link></small></p>
                  {postMeta.frontmatter.original_owner ? (
                    <p>
                      <small>
                        This article was originally posted at <ExternalLink
                        destination={postMeta.frontmatter.original_url}
                        title={postMeta.frontmatter.original_owner}
                      >
                        {postMeta.frontmatter.original_owner}
                      </ExternalLink>
                        &nbsp;on the {postMeta.frontmatter.original_date} by {postMeta.frontmatter.original_author}.
                      </small>
                    </p>
                  ) : (null) }
                </div>
              </div>
              <div className="">
                <p>
                  <small className={`text-muted`}><FontAwesomeIcon icon={['far', 'clock']}/> {post.timeToRead} min read</small>
                </p>
              </div>
              <Img fluid={post.frontmatter.cover_image.childImageSharp.fluid} className={`rounded mt-8`}/>
              {postMeta.frontmatter.image_credit_name ? (
                <small
                  className={`text-muted`}
                >Image credits: <ExternalLink
                  destination={postMeta.frontmatter.image_credit_url}
                  title={postMeta.frontmatter.image_credit_name}
                >{postMeta.frontmatter.image_credit_name}
                </ExternalLink>
                </small>
              ) : (null)}
              <div className="row justify-content-center">
                <SocialShare
                  className={`pt-3 pb-lg-0 pb-3`}
                  path={post.frontmatter.path}
                  title={post.frontmatter.title}
                  description={post.frontmatter.description}
                  tags={post.frontmatter.tags}
                />
              </div>
              <div className="content pt-lg-8">
                {renderAst(post.htmlAst)}
              </div>
              {postMeta.frontmatter.writing_credit_name ? (
                <div className="writing-credits pt-5">
                  <p className="text-muted"><small>Special thanks to <ExternalLink destination={postMeta.frontmatter.writing_credit_url} title={postMeta.frontmatter.writing_credit_name}>{postMeta.frontmatter.writing_credit_name}</ExternalLink>, I couldn't of written this article without your help 🙏</small></p>
                </div>
              ) : (null)}
              <div className="row justify-content-center text-center pt-5">
                <div className="col-12">
                  <p><strong className={``}>Have you enjoyed reading this article?</strong></p>
                  <p className="">Don't be selfish, share it with your friends 😉</p>
                </div>
                <SocialShare
                  path={post.frontmatter.path}
                  title={post.frontmatter.title}
                  description={post.frontmatter.description}
                  tags={post.frontmatter.tags}
                />
              </div>
              <div className="row mt-5 mt-lg-8">
                <div className="col-12">
                  <div className="border rounded">
                    <div className="p-5">
                      <p><strong>Got questions or feedback?</strong> Leave a comment, I <strong>will</strong> reply.</p>
                      <Comments
                        pagePath={post.frontmatter.path}
                        pageTitle={post.frontmatter.title}
                        pageIdentifier={post.id}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </article>
            <aside className="col-lg-3 col-12 pt-lg-0 pt-5">
              <div className="sidebar-content border ml-lg-5 sticky-top sticky-offset-header rounded">
                <Cta
                  type={post.frontmatter.cta_type}
                  serviceName={post.frontmatter.service_name}
                  actionText={post.frontmatter.action_text}
                  serviceLink={post.frontmatter.service_link}
                >
                  <div className="cta-content">
                    <p><strong>{post.frontmatter.cta_emphasis}</strong></p>
                    <p>{post.frontmatter.cta_text}</p>
                  </div>
                </Cta>
              </div>
            </aside>
          </div>
        </div>
        <section className="recent-articles mt-9">
          <div className="container">
            <div className="row py-9">
              <h2 className={`col-12`}>Latest articles</h2>
              <div className="d-none d-lg-block col-12">
                <LatestArticles
                  listStyle={`deck`}
                  showLimit={3}
                  excludeTitle={post.frontmatter.title}
                />
              </div>
              <div className="d-lg-none col-12">
                <LatestArticles
                  listStyle={`swipe`}
                  showLimit={3}
                  excludeTitle={post.frontmatter.title}
                />
              </div>
            </div>
          </div>
        </section>
      </DefaultLayout>
    )
  }
}

export default PostTemplate

export const postQuery = graphql`
  query BlogPostByPath($path: String! ) {
    postData:
      markdownRemark(frontmatter: {path: {eq: $path} }) {
        id
        htmlAst
        timeToRead
        frontmatter {
          path
          title
          description
          date(formatString: "Do MMMM, YYYY")
          cover_image{
            publicURL
            childImageSharp{
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          tags
          cta_type
          service_name
          action_text
          service_link
          cta_emphasis
          cta_text
        }
      }
    postMetaData:
        markdownRemark(frontmatter: {path: {eq: $path} }) {
      frontmatter {
        date(formatString: "YYYY-MM-DD")
        static_image
        canonical_url
        original_owner
        original_url
        original_date(formatString: "Do MMMM, YYYY")
        original_author
        image_credit_name
        image_credit_url
        writing_credit_name
        writing_credit_url
      }
    }
  }
`
