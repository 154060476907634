/**
 * This file is part of Keith-mifsud.me
 *
 * @licence Please view the Licence file supplied with this source code.
 *
 * @author Keith Mifsud <https://www.keith-mifsud.me>
 *
 * @copyright Keith Mifsud 2019 <mifsud.k@gmail.com>
 *
 * @since   2.0
 * @version 2.0 Second Major Release
 */
import React, { Component, Fragment } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class DeckList extends Component {
  shouldBeShown = (excludeTitle, articleTitle, showLimit, alreadyShown) => {
    let show = false
    if ((articleTitle !== excludeTitle) && (alreadyShown < showLimit)) {
      show = true
    }
    return show
  }
  render () {
    const {
      showLimit,
      excludeTitle
    } = this.props

    let shownArticles = 0
    let incrementShownArticles = () => {
      shownArticles++
    }
    return (
      <>
        <StaticQuery query={graphql`
            query {
              allMarkdownRemark (
                sort: {fields: [frontmatter___date], order: DESC}
                filter: {
                  fields:{
                    collection: {eq: "posts"}
                  }
                }
            ){
                edges{
                  node{
                    timeToRead
                    excerpt(pruneLength: 380)
                    frontmatter{
                      cover_image{
                        publicURL
                        childImageSharp{
                          fluid {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                      date(formatString: "Do MMMM, YYYY")
                      title
                      path
                      tags
                    }
                  }
                }
              }
            }
        `}
          render={data =>
            <div className="row">
              {data.allMarkdownRemark.edges.map(({ node }, index) => (
                <Fragment key={index}>
                {this.shouldBeShown(excludeTitle, node.frontmatter.title, showLimit, shownArticles) ? (
                  <div className="col-md-4">
                    <div className="card h-100 border rounded">
                      <Link to={node.frontmatter.path} title={node.frontmatter.title}>
                        <Img fluid={node.frontmatter.cover_image.childImageSharp.fluid} className={`img-fluid card-img-top`}/>
                      </Link>
                      <div className="card-body">
                        <Link to={node.frontmatter.path} className={`hover-none hover-lighten`}>
                          <h3 className="card-title hover-lighten" style={{ minHeight: '4rem' }}>
                            {node.frontmatter.title}
                          </h3>
                        </Link>
                        <small className="text-muted">
                          Published on {node.frontmatter.date}
                        </small>
                        <div className="tags pb-3 pt-3">
                          {node.frontmatter.tags.map(tag =>
                            <span key={tag} className={`badge badge-secondary mr-2`}>{tag}</span>
                          )}
                        </div>
                        <p className={`card-text text-secondary`} style={{ minHeight: '8rem' }}>
                          {node.excerpt}
                        </p>
                      </div>
                      <div className="card-footer bg-white border-0 text-right">
                        <small className={`text-muted`}><FontAwesomeIcon icon={['far', 'clock']}/> {node.timeToRead} min read</small>
                      </div>
                    </div>
                    {incrementShownArticles()}
                  </div>
                ) : (null)}
                </Fragment>
              ))}
            </div>
          }
        />
      </>
    )
  }
}

export default DeckList
