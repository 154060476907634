/**
 * This file is part of Keith-mifsud.me
 *
 * @licence Please view the Licence file supplied with this source code.
 *
 * @author Keith Mifsud <https://www.keith-mifsud.me>
 *
 * @copyright Keith Mifsud 2019 <mifsud.k@gmail.com>
 *
 * @since   2.0
 * @version 2.0 Second Major Release
 */
import React, { Component, Fragment } from 'react'
import { graphql, Link, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Icon from '../../Icon'

class SwipeList extends Component {
  shouldBeShown = (excludeTitle, articleTitle, showLimit, alreadyShown) => {
    let show = false
    if ((articleTitle !== excludeTitle) && (alreadyShown < showLimit)) {
      show = true
    }
    return show
  }
  render () {
    const {
      showLimit,
      excludeTitle
    } = this.props

    let shownArticles = 0
    let incrementShownArticles = () => {
      shownArticles++
    }
    return (
      <>
        <StaticQuery query={graphql`
            query {
              allMarkdownRemark (
                sort: {fields: [frontmatter___date], order: DESC}
                filter: {
                  fields:{
                    collection: {eq: "posts"}
                  }
                }
            ){
                edges{
                  node{
                    timeToRead
                    excerpt(pruneLength: 280)
                    frontmatter{
                      cover_image{
                        publicURL
                        childImageSharp{
                          fluid {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                      date(formatString: "Do MMMM, YYYY")
                      title
                      path
                      tags
                    }
                  }
                }
              }
            }
          `}
           render={data =>
             <div className="article-group">
               <div className="row">
                 {data.allMarkdownRemark.edges.map(({ node }, index, arr) => (
                   <Fragment key={index}>
                     {this.shouldBeShown(excludeTitle, node.frontmatter.title, showLimit, shownArticles) ? (
                   <div className={`col-12 article-item`}>
                     <div className="card h-100">

                         <Link to={node.frontmatter.path} title={node.frontmatter.title}>
                           <Img
                             fluid={node.frontmatter.cover_image.childImageSharp.fluid}
                             className={`img-fluid card-img-top`}
                           />
                         </Link>


                       <div className={`col-12 py-3 px-3 card-body`}>
                         <Link
                           to={node.frontmatter.path}
                           title={node.frontmatter.title}
                           className={`hover-none`}
                         >
                           <h3
                             className={`hover-lighten`}
                           >
                             {node.frontmatter.title}
                           </h3>
                         </Link>
                         <div>
                           <small className="text-muted">
                             Published on {node.frontmatter.date}
                           </small>
                         </div>
                         <div className="tags">
                           {node.frontmatter.tags.map(tag =>
                               <span key={tag} className="badge badge-secondary mr-2">
                             {tag}
                           </span>
                           )}
                         </div>
                         <p className={`text-secondary small pt-5 excerpt`}>
                           {node.excerpt}
                         </p>
                       </div>
                       <div className={`bg-white border-0 pt-2 card-footer`}>
                         <small className={`text-muted`}><FontAwesomeIcon
                           icon={['far', 'clock']}/> {node.timeToRead} min read
                         </small>
                       </div>
                     </div>
                     {incrementShownArticles()}
                   </div>
                     ) : (null)}
                   </Fragment>
                 ))}
               </div>
             </div>
           }
        />
        <div className="row">
          <div className="col-12 text-center pt-5">
            <Icon type={`Swipe`} className={`swipe-icon bounce-right`} />
          </div>
        </div>
      </>
    )
  }
}

export default SwipeList
